<template>
  <div class="survey">
    <div class="industry-second">
      <div class="industry-second-up">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row :gutter="24">
            <a-col :span="8">
              <!--<a-form-item label="行政区划：">-->
              <!--<a-select-->
              <!--mode="multiple"-->
              <!--style="width: 100%"-->
              <!--placeholder="请选择"-->
              <!--@change="handleChangeArea"-->
              <!--v-model="param.selectedAreaIds"-->
              <!--&gt;-->
              <!--<a-select-option-->
              <!--:value="item.id"-->
              <!--v-for="(item, index) in xzqh"-->
              <!--:key="index"-->
              <!--&gt;-->
              <!--{{ item.name }}-->
              <!--</a-select-option>-->
              <!--</a-select>-->
              <!--</a-form-item>-->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="水样类型：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChangeWater"
                  v-model="param.sampleTypes"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in arr2"
                    :key="index"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="水源类型：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChangeWaterSource"
                  v-model="param.waterTypes"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in arr3"
                    :key="index"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24" style="margin-top:20px">
            <a-col :span="5">
              <a-form-item label="时间：" :labelCol="{ style: 'width: 69px' }">
                <a-select
                  v-model="param.reportYear"
                  placeholder="请选择"
                  @change="handleChangeYear"
                >
                  <a-select-option v-for="(i, index) in year" :key="index">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item label="">
                <a-select
                  mode="multiple"
                  placeholder="请选择"
                  @change="handleChangeSeason"
                  v-model="param.season"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in arr5"
                    :key="index"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程类型：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChangeProject"
                  v-model="param.projectTypes"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in arr4"
                    :key="index"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" :style="{ textAlign: 'right' }">
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清除
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <!-- 达标率 -->
    <div class="survey-first">
      <div class="survey-firstone">
        <div class="survey-firstone-title">
          水样达标率：{{ rate1.goodPow }}%
        </div>
        <div class="survey-firstone-con">
          <div class="survey-firstone-con-up">
            <div class="green" :style="{ width: rate1.goodPow + '%' }"></div>
            <div class="red" :style="{ width: rate1.nogoodPow + '%' }"></div>
          </div>
          <div class="survey-firstone-con-ud">
            <div>达标{{ rate1.goodPow }}%</div>
            <div>不达标{{ rate1.nogoodPow }}%</div>
          </div>
        </div>
        <div class="survey-firstone-text">
          <div class="textone">
            <div class="greendot"></div>
            <div class="text">
              达标水样：{{ rate1.goodSampleNum }}件，占比{{ rate1.goodPow }}%
            </div>
          </div>
          <div class="textone">
            <div class="bluedot"></div>
            <div class="text">总水样数：{{ rate1.sampleNum }}件</div>
          </div>
        </div>
      </div>
      <div class="survey-firstone">
        <div class="survey-firstone-title">
          达标供水覆盖人口比例：{{ rate2.goodPeoPow }}%
        </div>
        <div class="survey-firstone-con">
          <div class="survey-firstone-con-up">
            <div class="green" :style="{ width: rate2.goodPeoPow + '%' }"></div>
            <div class="red" :style="{ width: rate2.nogoodPeoPow + '%' }"></div>
          </div>
          <div class="survey-firstone-con-ud">
            <div>达标{{ rate2.goodPeoPow }}%</div>
            <div>不达标{{ rate2.nogoodPeoPow }}%</div>
          </div>
        </div>
        <div class="survey-firstone-text">
          <div class="textone">
            <div class="greendot"></div>
            <div class="text">
              达标覆盖农村供水人口：{{ rate2.goodCoverPeo }}万人
            </div>
          </div>
          <div class="textone">
            <div class="bluedot"></div>
            <div class="text">
              检测覆盖农村供水总人口：{{ rate2.coverPeo }}万人
            </div>
          </div>
        </div>
      </div>
      <div class="survey-firstone">
        <div class="survey-firstone-title">
          供水工程达标率：{{ rate3.goodPlaPow }}%
        </div>
        <div class="survey-firstone-con">
          <div class="survey-firstone-con-up">
            <div class="green" :style="{ width: rate3.goodPlaPow + '%' }"></div>
            <div class="red" :style="{ width: rate3.nogoodPlaPow + '%' }"></div>
          </div>
          <div class="survey-firstone-con-ud">
            <div>达标{{ rate3.goodPlaPow }}%</div>
            <div>不达标{{ rate3.nogoodPlaPow }}%</div>
          </div>
        </div>
        <div class="survey-firstone-text">
          <div class="textone">
            <div class="greendot"></div>
            <div class="text">达标工程处数：{{ rate3.goodCoverPla }}处</div>
          </div>
          <div class="textone">
            <div class="bluedot"></div>
            <div class="text">检测工程处数：{{ rate3.coverPla }}处</div>
          </div>
        </div>
      </div>
      <div class="survey-firstone">
        <div class="survey-firstone-title">
          项次达标率：{{ rate4.goodDetectionPow }}%
        </div>
        <div class="survey-firstone-con">
          <div class="survey-firstone-con-up">
            <div
              class="green"
              :style="{ width: rate4.goodDetectionPow + '%' }"
            ></div>
            <div
              class="red"
              :style="{ width: rate4.nogoodDetectionPow + '%' }"
            ></div>
          </div>
          <div class="survey-firstone-con-ud">
            <div>达标{{ rate4.goodDetectionPow }}%</div>
            <div>不达标{{ rate4.nogoodDetectionPow }}%</div>
          </div>
        </div>
        <div class="survey-firstone-text">
          <div class="textone">
            <div class="greendot"></div>
            <div class="text">达标指标项次：{{ rate4.goodDetectionNum }}项</div>
          </div>
          <div class="textone">
            <div class="bluedot"></div>
            <div class="text">检测指标总项次：{{ rate4.detectionNum }}项</div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="survey-second">
      <div class="survey-second-l">
        <div class="industry-six-r">
          <div class="industry-thirdone-title">
            <div class="industry-thirdone-title-l"></div>
            <div class="industry-thirdone-title-r">
              <div class="industry-progress-tab">
                <div
                  class="industry-progress-tabone"
                  :class="
                    tabListeIndex2 == index ? 'progress-tabone-select' : ''
                  "
                  v-for="(item, index) in tabList"
                  :key="index"
                  @click="waterProject3(index)"
                >
                  {{ item }}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative;width:100%;height:100%">
            <div id="tuline3" style="position: relative;width:100%;height:100%">
              <div class="linesort">
                <img
                  v-if="sortup1"
                  src="@/assets/img/industry/sortud.png"
                  @click="sort1()"
                  alt=""
                />
                <img
                  v-if="!sortup1"
                  src="@/assets/img/industry/sortup.png"
                  @click="sort1()"
                  alt=""
                />
              </div>
              <div id="line5" style="width:100%;height:160%;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="survey-second-r">
        <div class="survey-second-rtitle" @click="toDetail()">
          查看详情 >
        </div>
        <div style="margin-top:20px" id="tableline3">
          <a-table
            style="text-align: center;"
            :columns="columns"
            :data-source="data"
            :pagination="false"
            bordered
            size="middle"
            :scroll="{ x: 'calc(50%)', y: 540 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import { getConsumQuality, getConsumMultiQuality } from "@/api/water/index";
import { getAreaTreeList } from "@/api/basic/common";

// 表格1数据
const columns = [
  // {
  //   title: "城市名称",
  //   dataIndex: "name",
  //   key: "name",
  //   width: 180
  //   // fixed: 'left',
  // },
  // {
  //   title: "水质达标率",
  //   dataIndex: "percent1",
  //   key: "percent1",
  //   width: 120,
  //   sorter: (a, b) => a.percent1 - b.percent1
  // }
];
// 表格1
const data = [
  // {
  //   key: 1,
  //   name: "太原",
  //   percent1: 1
  // },
  // {
  //   key: 2,
  //   name: "运城",
  //   percent1: 1
  // },
  // {
  //   key: 3,
  //   name: "临汾",
  //   percent1: 1
  // },
  // {
  //   key: 4,
  //   name: "长治",
  //   percent1: 1
  // },
  // {
  //   key: 5,
  //   name: "阳泉",
  //   percent1: 1
  // },
  // {
  //   key: 6,
  //   name: "大同",
  //   percent1: 1
  // },
  // {
  //   key: 7,
  //   name: "晋城",
  //   percent1: 1
  // },
  // {
  //   key: 8,
  //   name: "忻州",
  //   percent1: 1
  // },
  // {
  //   key: 9,
  //   name: "晋中",
  //   percent1: 1
  // },
  // {
  //   key: 10,
  //   name: "朔州",
  //   percent1: 1
  // },
  // {
  //   key: 11,
  //   name: "吕梁",
  //   percent1: 1
  // }
];
export default {
  data() {
    return {
      aa: undefined,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      widthg: "72%",
      widthr: "28%",
      xzqh: ["江苏省", "北京市", "山西省"], //测试数据/行政区划
      tabList: [
        "水样达标对比",
        "供水工程达标率对比",
        "项次达标率对比",
        "达标供水覆盖人口比例"
      ], //tab
      tableList: [
        "水样达标率",
        "供水工程达标率",
        "项次达标率",
        "达标供水覆盖人口比例"
      ],
      rate1: {
        goodPow: 0, //水样达标率
        nogoodPow: 0, //水样不达标率
        sampleNum: 0, //水样数量
        goodSampleNum: 0 //达标水样数量
      },
      rate2: {
        goodPeoPow: 0, //达标供水覆盖人口比例
        nogoodPeoPow: 0, //不达标供水覆盖人口比例
        coverPeo: 0, //覆盖人口
        goodCoverPeo: 0 //达标覆盖人口
      },
      rate3: {
        goodPlaPow: 0, //供水工程达标率
        nogoodPlaPow: 0, //供水工程不达标率
        coverPla: 0, //工程处数
        goodCoverPla: 0 //达标工程处数
      },
      rate4: {
        goodDetectionPow: 0, //项次达标率
        nogoodDetectionPow: 0, //项次不达标率
        detectionNum: 0, //检测项数量
        goodDetectionNum: 0 //达标检测项数量
      },

      tabListeIndex2: 0, //图 分类index
      sortup1: false, //图 排序
      data,
      columns,
      year: [],
      // 筛选条件
      param: {
        defaultAreaId: undefined,
        selectedAreaIds: undefined,
        areaIds: undefined,
        projectTypes: [],
        reportYear: undefined,
        sampleTypes: [],
        season: [],
        waterTypes: []
      },
      arr1: [],
      arr2: [],
      arr3: [],
      arr4: [
        { id: 1, name: "不限" },
        { id: 2, name: "2020年" },
        { id: 3, name: "2016年1月1日至2020年12月31日" },
        { id: 4, name: "2011年1月1日至2015年12月31日" },
        { id: 5, name: "2006年1月1日至2010年12月31日" },
        { id: 6, name: "十一五以前（2005年12月31日以前）" }
      ],
      arr5: [
        // { id: 1, name: "丰水期" },
        // { id: 2, name: "枯水期" },
      ],
      consumMultiData: [],
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      userAreaId: {
        id: 1
      },
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      }
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
    this.getAreaList();
  },
  mounted() {},
  methods: {
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaIds = value[value.length - 1];
      console.log(value);
    },
    async getData() {
      await baseMenuList({
        menu: "水样类型"
      }).then(res => {
        this.arr2 = res.data.list;
      });
      await baseMenuList({
        menu: "水源类型"
      }).then(res => {
        this.arr3 = res.data.list;
      });
      await baseMenuList({
        menu: "集中设施"
      }).then(res => {
        this.arr4 = res.data.list;
      });
      await baseMenuList({
        menu: "供水季节"
      }).then(res => {
        this.arr5 = res.data.list;
        // this.param.season = [res.data.list[0]?.value];
      });

      this.getConsum();
      this.getConsumMult();
    },
    getAreaList() {
      let data = getDefaultArea();
      console.log(data);
      this.param.defaultAreaId = data.id;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaIds) {
        this.param.areaIds = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaIds
      }).then(res => {
        if (res.code == 200) {
          this.xzqh = res.data.list;
        }
        this.getData();
      });
    },
    // 统计数据
    getConsum() {
      getConsumQuality({
        areaIds: this.param.areaIds,
        projectTypes: this.param.projectTypes,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleTypes,
        season: this.param.season,
        waterTypes: this.param.waterTypes
      }).then(res => {
        this.rate1.goodPow = res.data.goodPow || 0;
        this.rate1.nogoodPow = (100 - res.data.goodPow).toFixed(2);
        this.rate1.sampleNum = res.data.sampleNum || 0;
        this.rate1.goodSampleNum = res.data.goodSampleNum || 0;

        this.rate2.goodPeoPow = res.data.goodPeoPow || 0;
        this.rate2.nogoodPeoPow = (100 - res.data.goodPeoPow).toFixed(2);
        this.rate2.coverPeo = res.data.coverPeo || 0;
        this.rate2.goodCoverPeo = res.data.goodCoverPeo || 0;

        this.rate3.goodPlaPow = res.data.goodPlaPow || 0;
        this.rate3.nogoodPlaPow = (100 - res.data.goodPlaPow).toFixed(2);
        this.rate3.coverPla = res.data.coverPla || 0;
        this.rate3.goodCoverPla = res.data.goodCoverPla || 0;

        this.rate4.goodDetectionPow = res.data.goodDetectionPow || 0;
        this.rate4.nogoodDetectionPow = (
          100 - res.data.goodDetectionPow
        ).toFixed(2);
        this.rate4.detectionNum = res.data.detectionNum || 0;
        this.rate4.goodDetectionNum = res.data.goodDetectionNum || 0;
      });
    },
    // 区域比较
    getConsumMult() {
      getConsumMultiQuality({
        areaIds: this.param.areaIds,
        projectTypes: this.param.projectTypes,
        queryBy: 0,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleTypes,
        season: this.param.season,
        waterTypes: this.param.waterTypes
      }).then(res => {
        if (res.code == 200) {
          this.consumMultiData = res.data.list;
          this.handleConsumMultiData();
        }
      });
    },
    sortByNumber(a, b) {
      if (this.sortup1) {
        if (this.tabListeIndex2 == 0) {
          return (
            parseInt(b.goodPow ? b.goodPow : 0) -
            parseInt(a.goodPow ? a.goodPow : 0)
          );
        }
        if (this.tabListeIndex2 == 1) {
          return (
            parseInt(b.goodPlaPow ? b.goodPlaPow : 0) -
            parseInt(a.goodPlaPow ? a.goodPlaPow : 0)
          );
        }
        if (this.tabListeIndex2 == 2) {
          return (
            parseInt(b.goodDetectionPow ? b.goodDetectionPow : 0) -
            parseInt(a.goodDetectionPow ? a.goodDetectionPow : 0)
          );
        }
        if (this.tabListeIndex2 == 3) {
          return (
            parseInt(b.goodPeoPow ? b.goodPeoPow : 0) -
            parseInt(a.goodPeoPow ? a.goodPeoPow : 0)
          );
        }
      } else {
        if (this.tabListeIndex2 == 0) {
          return (
            parseInt(a.goodPow ? a.goodPow : 0) -
            parseInt(b.goodPow ? b.goodPow : 0)
          );
        }
        if (this.tabListeIndex2 == 1) {
          return (
            parseInt(a.goodPlaPow ? a.goodPlaPow : 0) -
            parseInt(b.goodPlaPow ? b.goodPlaPow : 0)
          );
        }
        if (this.tabListeIndex2 == 2) {
          return (
            parseInt(a.goodDetectionPow ? a.goodDetectionPow : 0) -
            parseInt(b.goodDetectionPow ? b.goodDetectionPow : 0)
          );
        }
        if (this.tabListeIndex2 == 3) {
          return (
            parseInt(a.goodPeoPow ? a.goodPeoPow : 0) -
            parseInt(b.goodPeoPow ? b.goodPeoPow : 0)
          );
        }
      }
    },
    handleConsumMultiData() {
      let title = [];
      let data = [];
      this.data = [];
      this.consumMultiData.sort(this.sortByNumber);
      for (let i in this.consumMultiData) {
        let result = this.consumMultiData[i];
        title.push(result.adName);
        if (this.tabListeIndex2 == 0) {
          data.push(result.goodPow);
          this.data.push({
            num: Number(i) + 1,
            key: i,
            name: result.adName,
            percent1: result.goodPow
          });
        }
        if (this.tabListeIndex2 == 1) {
          data.push(result.goodPlaPow);
          this.data.push({
            num: Number(i) + 1,
            key: i,
            name: result.adName,
            percent1: result.goodPlaPow
          });
        }
        if (this.tabListeIndex2 == 2) {
          data.push(result.goodDetectionPow);
          this.data.push({
            num: Number(i) + 1,
            key: i,
            name: result.adName,
            percent1: result.goodDetectionPow
          });
        }
        if (this.tabListeIndex2 == 3) {
          data.push(result.goodPeoPow);
          this.data.push({
            num: Number(i) + 1,
            key: i,
            name: result.adName,
            percent1: result.goodPeoPow
          });
        }
      }
      this.columns = [
        {
          title: "序列",
          dataIndex: "num",
          key: "num",
          width: 100,
          align: "center"
          // fixed: 'left',
        },
        {
          title: "城市名称",
          dataIndex: "name",
          key: "name",
          width: 100,
          align: "center"
          // fixed: 'left',
        },
        {
          title: this.tableList[this.tabListeIndex2],
          dataIndex: "percent1",
          key: "percent1",
          width: 120,
          align: "center",
          sorter: (a, b) => a.percent1 - b.percent1
        }
      ];
      this.$chart.line5("line5", this.tabListeIndex2, {
        title: title,
        data: data
      });
    },
    // 切换行政区划
    handleChangeArea(value) {
      console.log(value);
      this.param.areaIds = value;
      this.param.selectedAreaIds = value;
    },
    // 切换水样类型
    handleChangeWater(value) {
      console.log(value);
      this.param.sampleTypes = value;
    },
    // 切换水源类型
    handleChangeWaterSource(value) {
      console.log(value);
      this.param.waterTypes = value;
    },
    // 切换工程类型
    handleChangeProject(value) {
      console.log(value);
      this.param.projectTypes = value;
    },
    //单选
    handleChangeYear(value) {
      console.log(`selected ${value}`);
      this.param.reportYear = this.year[value];
    },
    handleChangeSeason(value) {
      this.param.season = value;
    },
    //多选
    handleChange(value) {
      console.log(`selected ${value}`);
      // this.param.areaIds.push(value)
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    toDetail() {
      this.$router.push({
        path: "/index/waterQuality/index/detail",
        query: {
          areaIds: JSON.stringify(this.param.areaIds),
          projectTypes: JSON.stringify(this.param.projectTypes),
          reportYear: this.param.reportYear,
          sampleTypes: JSON.stringify(this.param.sampleTypes),
          season: JSON.stringify(this.param.season),
          waterTypes: JSON.stringify(this.param.waterTypes)
        }
      });
    },
    //查询
    handleSearch(e) {
      e.preventDefault();
      this.getConsum();
      this.getConsumMult();
    },
    //清除
    handleReset() {
      this.param.areaIds = this.param.defaultAreaId;
      this.param.selectedAreaIds = undefined;
      this.param.projectTypes = [];
      this.param.sampleTypes = [];
      this.param.waterTypes = [];
      this.param.reportYear = 2020;
      this.param.season = undefined;
      this.getConsum();
      this.getConsumMult();
    },

    //
    waterProject3(index) {
      this.tabListeIndex2 = index;
      this.handleConsumMultiData();
    },
    // 集中供水率对比/排序
    sort1() {
      console.log(this.sortup1);
      this.sortup1 = !this.sortup1;
      this.handleConsumMultiData();
    }
  }
};
</script>

<style scoped lang="less">
#tableline3 /deep/ .ant-table-body {
  overflow-x: hidden !important;
}
.industry-second {
  width: 100%;
  background: #ffffff;
  .industry-second-up {
    padding: 30px 20px 15px 20px;
    border-bottom: 1px solid #e1e1e1;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 200px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.survey-first {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .survey-firstone {
    width: 24%;
    // height: 100%;
    background: #ffffff;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    .survey-firstone-title {
      font-size: 14px;
      font-weight: bold;
    }
    .survey-firstone-con {
      width: 90%;
      margin: 0 auto;
      .survey-firstone-con-up {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 24px;
        margin: 0 auto;
        margin-top: 30px;
        .green {
          background: #5dba7f;
          height: 24px;
        }
        .red {
          background: #e65564;
          height: 24px;
        }
      }
      .survey-firstone-con-ud {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .survey-firstone-text {
      margin-top: 20px;
      .textone {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        height: 35px;
        .bluedot {
          width: 14px !important;
          height: 14px !important;
          background: #5d6cba;
          border-radius: 50%;
          margin-top: 5px;
        }
        .greendot {
          width: 14px !important;
          height: 14px !important;
          background: #5dba7f;
          border-radius: 50%;
          margin-top: 5px;
        }
        .text {
          width: 85%;
          margin-left: 10px;
        }
      }
    }
  }
}
.survey-second {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .survey-second-l {
    width: 63.5%;
    min-height: 400px;
    height: 650px;
    background: #ffffff;
    .industry-six-r {
      width: 100%;
      height: 400px;
      background: #ffffff;
      padding: 20px;
      .industry-thirdone-title {
        display: flex;
        align-items: center;
        justify-content: center;
        .industry-thirdone-title-l {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
          .line {
            width: 4px;
            height: 16px;
            background: #1890ff;
            border-radius: 2px;
          }
          .title {
            margin-left: 8px;
          }
        }
        .industry-thirdone-title-r {
          display: flex;
          cursor: pointer;
          .industry-progress-tab {
            width: 100%;
            display: flex;
            font-size: 12px;
            justify-content: space-between;
            // margin-top: 4px;
            .industry-progress-tabone {
              position: relative;
              cursor: pointer;
              margin-left: 20px;
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              div {
                width: 50px;
                height: 1px;
                background: #1890ff;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                bottom: -4px;
                display: none;
              }
            }
            .progress-tabone-select {
              color: #1890ff;
              div {
                display: block;
              }
            }
          }
          .industry-thirdone-title-rdone {
            width: 80px;
            height: 24px;
            line-height: 24px;
            background: #ffffff;
            border: 1px solid #e1e1e1;
            font-size: 12px;
            color: #999999;
            text-align: center;
          }
          .industry-thirdone-title-rselect {
            color: #ffffff;
            background: #1890ff;
            border: 1px solid #1890ff;
          }
        }
      }
      .linesort {
        right: 40px;
        top: 20px;
        position: absolute;
        z-index: 9;
        cursor: pointer;
        img {
          width: 21px;
          height: 19px;
        }
      }
    }
  }
  .survey-second-r {
    width: 35%;
    // height: 400px;
    background: #ffffff;
    padding: 20px;
    .survey-second-rtitle {
      text-align: right;
      cursor: pointer;
    }
  }
}
</style>
